export const usersHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'name',
        label: 'Full Name',
    },
    {
        id: 'switch',
        label: '',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'phone_no',
        label: 'Phone number',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'gender',
        label: 'Gender',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const categoriesHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'event_category_name',
        label: 'Category Name',
    },
    {
        id: 'actions',
        label: 'Category ID',
    },
    {
        id: 'Description',
        label: 'Description',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const subCategoriesHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'event_sub_category_name',
        label: 'Sub Category Name',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'icon',
        label: 'Sub Category Image',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const eventHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'event_name',
        label: 'Name',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'event_owner',
        label: 'Owner',
    },
    {
        id: 'event_category_name',
        label: 'Category',
    },
    {
        id: 'event_sub_category_name',
        label: 'Sub Category',
    },
    {
        id: 'event_location',
        label: 'Location',
    },
    {
        id: 'event_time',
        label: 'Time',
    },
    {
        id: 'event_capacity',
        label: 'Capacity',
    },
    {
        id: 'event_description',
        label: 'Description',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const eventPackagesHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'package_name',
        label: 'Package Name',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const paymentHeadCells = [
    {
        id: 'id',
        label: 'S/Nos',
    },
    {
        id: 'phone_number',
        label: 'Phone Numbers',
    },
    {
        id: 'total_amount',
        label: 'Price',
    },
    {
        id: 'generated_id',
        label: 'Customer ID',
    },
    {
        id: 'voted_for',
        label: 'Nominee',
    },
    {
        id: 'vote_numbers',
        label: 'Votes Count',
    },
    {
        id: 'voted_for_code',
        label: 'Nominee Code',
    },
    {
        id: 'longitude',
        label: 'Longitude',
    },
    {
        id: 'latitude',
        label: 'Latitude',
    },
    {
        id: 'payment_status',
        label: 'Payment Status',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'Ipaddress',
        label: 'IP Address',
    },
]

export const dekaniaHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'dekania_name',
        label: 'Dekania Name',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]